@media (max-width:1199px){
    /*.about_img img {
        margin-left: -180px;
    }*/
    .about_team_div{
        padding-top: 50px;
    }
    .about_team .item-img-overlay{
        top: 140px;
        bottom: 0px;
        opacity: .8;
    }
    .about_team .item-img-overlay .overlay-info h6{
        font-size: 6px;
        transform: none;
        align-self: center;
        margin-bottom: 0px;
        margin-right: 3px;
    }
    .overlay-info{
        display: flex;
        justify-content: center;
    }
    .icons-team i{
        font-size: .6em;
    }
    .work_item{
        height: 30vh;
    }
    .blog_post .post_content .blog-meta span{
        font-size: 14px;
    }
    .about_content{
        padding-right: 0px;
    }
    .promo_section .promo_box_one{
        padding: 0px 15px;
    }
    .promo_section .promo_skill{
        padding-left: 15px;
        padding-right: 15px;
    }
    .banner_shap,.banner_shap_two {
        background-position: 72%;
    }
}
@media (max-width:991px){
    .react-parallax.banner_area{
        min-height: 600px;
    }
    .react-parallax-bgimage{
        height: 100% !important;
        width: 100% !important;
        background-size: cover !important;
        left: 0 !important;
        transform: translate3d(0%, 0px, 0px) !important;
    }
    .about_img{
        text-align: center;
    }
    .about_content .theme_btn + .theme_btn {
        margin-left: 6px;
    }
    .about_team .item-img-overlay{
        top: 280px;
    }
    .about_team .item-img-overlay .overlay-info h6{
        font-size: 12px;
        margin-right: 6px;
    }
    .icons-team i{
        font-size: .8em;
    }
    .contact-area .contact_info {
        padding-left: 0;
    }
    .blog_post{
        margin-bottom: 50px;
    }
    .blog_area .row{
        margin-bottom: -50px;
    }
    .navbar{
        padding: 0px 15px;
    }
    .navbar .login{
        margin-top: 0px;
        margin-left: auto;
        margin-right: 20px;
    }
    .navbar-toggler span{
        background: #fff;
    }
    .dark_menu .navbar-toggler span{
        background: #040c2c;
    }
    .navbar_fixed .navbar-toggler span,.navbar .navbar-nav .nav-item .nav-link:before{
        background: #040c2c;
    }
    .red_color .navbar_fixed .navbar-toggler span{
        background: #ff2a39;
    }
    .navbar .navbar-nav .nav-item{
        margin-left: 0px;
    }
    .navbar-nav{
        background: #fff;
        padding: 20px 20px 15px;
    }
    .navbar .navbar-nav .nav-item .nav-link{
        color: #040c2c;
        display: inline-block;
    }
    .about_area_two .about_item{
        margin-bottom: 30px;
    }
    .about_area_two{
        padding-bottom: 90px;
    }
    .promo_section .promo_box_one{
        padding: 80px 30px 100px;
    }
    .promo_section .promo_video img{
        position: relative;
    }
    .promo_section .promo_skill{
        padding: 90px 30px 80px;
    }
    .team_item{
        margin-bottom: 40px;   
    }
    .team_area{
        padding-bottom: 60px;
    }
    .banner_area_two .banner_content h2 {
        font-size: 58px;
    }
    .navbar .navbar-nav .nav-item + .nav-item{
        margin-top: 5px;
    }
}

@media (max-width:767px){
    .project_count_area .row{
        margin-bottom: -30px;
    }
    .project_count_area .counter_item{
        margin-bottom: 30px;
    }
    .contact-area .contact_info{
        margin-bottom: 50px;
    }
    .about_img img{
        max-width: 100%;
        margin-left: 0px;
    }
    .about_content{
        padding: 0px 0px 40px;
    }
    .work_item{
        height: 50vh;
    }
    .navbar-collapse{
        margin-left: -15px;
        margin-right: -15px;
    }
    .navbar-right{
        display: none;
    }
    .banner_content h5{
        margin-bottom: 10px;
    }
    .banner_content h4{
        letter-spacing: 0px;
        margin-bottom: 0px;
    }
    .banner_content{
        padding-top: 45px;
    }
    .navbar-collapse{
        max-height: 275px;
        overflow-y: scroll; 
    }
    .banner_area_two .banner_content h2 {
        font-size: 40px;
    }
    .navbar .logo_h img {
        max-width: 80%;
    }
    .navbar .login{
        padding: 3px 27px;
    }
    .navbar_fixed .navbar .navbar-toggler{
        height: 70px;
    }
    .navbar-light .navbar-toggler{
        transition: all 0.3s ease-in-out;
    }
    .react-parallax.banner_area {
        min-height: 500px;
    }
}
@media(max-width:576px){
    .portfolio_menu li{
        margin-right: 0px;
        margin-bottom: 8px;
    }
    .portfolio img{
        width: 100%;
    }
    .testimonial_area .testimonial_slider{
        padding: 0px;
    }
    .title_p br{
        display: none;    
    }
    .title_h2{
        font-size: 27px;
        line-height: 40px;
    }
    .banner_content h2{
        font-size: 38px;
    }
    .about_content .theme_btn{
        margin-bottom: 8px;
        display: block;
        margin-left: 0;
    }
    .about_content .theme_btn + .theme_btn{
        margin-left: 0;
    }
    .work_area,.portfolio_area{
        padding: 80px 0px 60px;
    }
    .project_count_area,.clients_logo_area,.blog_area,.contact-area{
        padding: 90px 0px;
    }
}
@media (max-width:450px){
    .navbar.navbar_fixed .login{
        display: none;
    }
    .about_team .item-img-overlay{
        top: 125px;
        bottom: 0px;
        opacity: .8;
    }
    .about_team .item-img-overlay .overlay-info h6{
        font-size: 7px;
        width: 60%;
    }
    .icons-team i{
        font-size: .5em;
    }

}